import { motion, useInView } from "framer-motion"
import { useEffect, useMemo, useRef } from "react"

import { Carousel } from "@app/components/carousel"

import { StepCard } from "./StepCard"
import { stepCardCarousel, stepCardList, stepsSection } from "./styles.css"

export type StepCardType = {
  badgeText?: string
  description: string
  imageSrc: string
  step: string
  title: string
}

const getCardList: (withCoupon: boolean) => StepCardType[] = (withCoupon) => [
  {
    badgeText: "sellingsteps_card01_badge",
    description: "sell:sellingsteps_card01_body",
    imageSrc: "/images/promozione-fee/illustrations/valuation.svg",
    step: "sellingsteps_card01_label",
    title: "sellingsteps_card01_header",
  },
  {
    badgeText: "sellingsteps_card02_badge",
    description: withCoupon
      ? "sell:AdTraction_sellingsteps_card02_body_coupon"
      : "sell:sellingsteps_card02_body",
    imageSrc: "/images/promozione-fee/illustrations/phone.svg",
    step: "sellingsteps_card02_label",
    title: "sellingsteps_card02_header",
  },
  {
    badgeText: "sellingsteps_card03_badge",
    description: "sell:sellingsteps_card03_body",
    imageSrc: "/images/promozione-fee/illustrations/sell.svg",
    step: "sellingsteps_card03_label",
    title: "sellingsteps_card03_header",
  },
  {
    description: "sell:sellingsteps_card04_body",
    imageSrc: "/images/promozione-fee/illustrations/keys.svg",
    step: "sellingsteps_card04_label",
    title: "sellingsteps_card04_header",
  },
]

const stepCardListVariant = {
  visible: {
    transition: {
      staggerChildren: 0.15,
    },
  },
}

export const StepCardList = ({
  className,
  onSwipe,
  withCoupon = false,
}: {
  className?: string
  onSwipe?: (nextStepIndex: number) => void
  withCoupon?: boolean
}) => {
  const cardList = useMemo(() => getCardList(withCoupon), [withCoupon])
  const targetRef = useRef(null)

  return (
    <div ref={targetRef} className={stepsSection}>
      <motion.div
        className={`${stepCardList} ${className}`}
        variants={stepCardListVariant}
        viewport={{ amount: 1, root: targetRef }}
        whileInView="visible"
      >
        {cardList.map((item) => (
          <StepCard key={item.title} item={item} />
        ))}
      </motion.div>

      <div className={`${stepCardCarousel} ${className}`}>
        <Carousel onSwipe={onSwipe}>
          {cardList.map((item, index) => (
            <StepCard key={index} item={item} />
          ))}
        </Carousel>
      </div>
    </div>
  )
}
